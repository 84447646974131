.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.tag-box {
  background-color: #d1fae5; /* Açık yeşil kutu rengi */
  color: #065f46; /* Koyu yeşil yazı rengi */
  font-weight: 600;
  font-size: 0.75rem; /* 12px */
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.25rem 0.5rem;
  border-radius: 0.375rem; /* Yuvarlatılmış köşeler */
  display: inline-block;
}

.static-image-upload-container {
  margin: 0 auto;
  max-width: 800px;
}

.dropzone {
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dropzone p {
  font-size: 16px;
}

.dropzone:hover {
  background-color: #f9f9f9;
}

img {
  display: block;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

/* Sidebar açıkken tüm içerik kaydırılır */
.flex {
  transition: margin-left 0.3s ease;
}

.ml-64 {
  margin-left: 16rem; /* Sidebar genişliği kadar sağa kaydırma */
}

@tailwind base;
@tailwind components;
@tailwind utilities;
