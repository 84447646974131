@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-blue-100 font-sans;
}

.dropzone {
  @apply w-full max-w-2xl p-8 border-4 border-dashed border-gray-400 rounded-lg bg-white;
}

.alert {
  @apply p-4 mb-4 rounded;
}

.alert-success {
  @apply bg-green-100 border border-green-400 text-fuchsia-700;
}

.alert-error {
  @apply bg-red-100 border border-red-400 text-red-700;
}

/* Ensure images fit within their containers without cropping */
img {
  object-fit: contain;
}

.link {
  display: block;
  color: #282727;
  padding: 10px 15px;
  margin-bottom: 5px;
  border-radius: 4px;
  text-decoration: none;
}

.active-link {
  display: block;
  color: #ffffff;
  background-color: #9333ff;
  padding: 10px 15px;
  margin-bottom: 5px;
  border-radius: 4px;
  text-decoration: none;
}

/* CSS for loading spinner */
.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
